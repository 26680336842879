export default function PenIcon(): JSX.Element {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <path
          d="M511.886,179.438L511.886,179.438l0.114-0.114L331.614,0l-28.29,28.117l-0.206,0.207l0,0l-0.114,0.114
		l2.195,2.179L72.618,261.828L0.719,490.672C-2.617,501.375,6.157,511.996,16.645,512c1.523-0.003,3.149-0.289,4.77-0.758
		l230.191-71.476l243.191-241.762l1.77-1.758l0,0l0.114-0.113l-0.797-0.79l15.953-15.859L511.886,179.438z M266.367,373.953
		c-1.449-11.617-6.683-22.473-15.046-30.785c-0.762-0.762-1.618-1.426-2.383-2.188l184.23-183.144l25.367,25.218L339.434,301.453
		L266.367,373.953z M171.985,264.484c-0.766-0.762-1.434-1.613-2.199-2.371c-7.309-7.27-16.516-11.98-26.414-14.098
		c-1.558-0.363-3.098-0.812-4.691-1.046L330.758,56.028l25.458,25.309L171.985,264.484z M109.141,276.336l21.649-1.047
		c7.05-0.379,13.633,2.18,18.59,7.11c4.957,4.93,7.442,11.378,7.062,18.39c-0.762,14.981,4.957,29.578,15.539,40.102
		c10.68,10.617,25.367,16.301,40.434,15.543c6.961-0.469,13.539,2.09,18.5,7.019c4.957,4.93,7.531,11.473,7.058,18.386l-1.05,21.524
		l-4.672,4.644L87.969,452.844l-28.511-28.34l45.011-143.523L109.141,276.336z M420.297,145.04L235.106,329.133l-0.554,0.55l0,0
		l-0.114,0.114l2.02,2.008l1.562,1.742c-8.223-4.164-17.622-6.21-27.133-5.738c-6.961,0.282-13.633-2.183-18.59-7.113
		c-4.961-4.93-7.343-11.469-7.062-18.387c0.383-7.605-0.926-15.113-3.652-22.074c-0.629-1.711-1.301-3.406-2.086-5.055l3.641,3.618
		L368.992,94.04l51.047,50.742L420.297,145.04z"
        ></path>
      </g>
    </svg>
  );
}
