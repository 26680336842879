export default function SpoonIcon(): JSX.Element {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <path
          d="M484.269,28.058c-17.839-17.836-42.671-27.66-69.926-27.66c-23.398,0-47.519,7.27-69.762,21.031
		c-41.191,25.473-68.125,61.586-82.343,110.402c-1.867,6.406-3.618,12.66-5.309,18.695c-5.836,20.848-11.77,42.058-17.465,49.726
		c-4.848,6.531-20.254,23.832-69.078,66.379c-27.918,24.343-78.168,68.465-101.098,90.523
		c-16.742,16.11-39.375,37.918-52.554,50.703c-19.465,18.859-22.317,49.695-6.622,71.73c3.406,4.793,8.333,10.074,10.458,12.195
		c3.027,3.031,6.73,6.129,10.683,8.946c9.84,7.007,22.02,10.871,34.301,10.871c15.086,0,28.906-5.684,38.914-16.008
		c12.789-13.188,34.594-35.817,50.699-52.558c22.316-23.199,66.289-73.297,90.519-101.094
		c42.543-48.812,59.851-64.238,66.379-69.086c1.378-1.015,4.949-3.207,13.27-6.32l1.683-0.641c3.832-1.386,8.851-3.008,15.375-4.953
		l35.07-9.606l3.011-0.828l1.715-0.926c47.977-14.355,83.535-41.137,108.707-81.839C521.304,118.562,518.64,62.43,484.269,28.058z
		 M65.555,484.277c-6.644,0-13.195-2.062-18.453-5.805c-2.746-1.957-5.25-4.046-7.234-6.031c-1.856-1.851-5.426-5.789-7.5-8.706
		c-7.918-11.122-6.465-26.711,3.39-36.262c13.16-12.762,35.758-34.542,52.473-50.625c22.637-21.777,72.442-65.492,100.106-89.613
		c38.726-33.746,63.304-57.531,73.066-70.691c8.706-11.727,14.754-33.325,21.754-58.332c1.687-6.039,3.442-12.305,5.32-18.738
		c12.41-42.622,34.809-72.742,70.481-94.805c17.922-11.09,37.074-16.946,55.386-16.946c19.958,0,37.93,6.981,50.602,19.657
		c25.226,25.226,26.317,67.82,2.715,105.988c-22.066,35.679-52.188,58.074-94.809,70.484c-1.11,0.321-2.61,1.129-2.61,1.129
		l-35.355,9.688c-6.164,1.832-12.149,3.718-17.61,5.699l-1.504,0.574c-8.867,3.312-15.414,6.582-19.992,9.976
		c-13.168,9.774-36.953,34.356-70.695,73.07c-24.05,27.594-67.672,77.297-89.61,100.106c-16.082,16.718-37.855,39.309-50.625,52.48
		C80.035,481.538,73.179,484.277,65.555,484.277z"
        ></path>
      </g>
    </svg>
  );
}
