export default function ToiletIcon(): JSX.Element {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <path
          d="M437.311,225.492H267.444H74.69H35.307v39.387c0,92.59,95.461,167.152,174.504,167.152h19.062
		c0.34,2.641,0.73,5.516,1.258,8.523l6.79,38.84L242.62,512h33.098h139.367c33.973,0,61.609-27.641,61.609-61.61V264.879v-39.387
		H437.311z M179.776,387.914c-0.793-0.238-1.59-0.469-2.386-0.719c-14.879-4.801-30.238-12.535-44.414-22.648
		c-0.141-0.102-0.282-0.196-0.422-0.301c-1.082-0.774-2.141-1.586-3.203-2.387c-0.985-0.746-1.973-1.484-2.938-2.25
		c-0.191-0.157-0.383-0.313-0.574-0.469c-29.102-23.359-51.148-56.938-51.148-94.262h192.754h169.867V450.39
		c0,12.274-9.954,22.223-22.226,22.223H275.717l-6.79-38.84c-0.75-4.286-1.199-8.614-1.781-12.926
		c-2.16-15.926-15.817-28.203-32.336-28.203h-25c-8.75,0-18.043-1.383-27.492-3.977C181.475,388.43,180.624,388.168,179.776,387.914
		z"
        ></path>
        <path
          d="M405.799,191.027l68.977-157.973c2.438-5.57,2.554-11.766,0.328-17.434
		c-2.222-5.66-6.515-10.113-12.039-12.511l-2.71-1.199c-2.898-1.266-5.969-1.91-9.11-1.91c-9.051,0-17.242,5.359-20.875,13.652
		l-55.39,126.871l-22.055,50.344h0.07l-0.07,0.16H405.799z"
        ></path>
      </g>
    </svg>
  );
}
