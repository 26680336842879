import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION || '',
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID || '',
  }
});

console.log('### API ENDPOINT = ' + process.env.REACT_APP_API_ENDPOINT);

// Amplify.configure({
//   Auth: {
//     region: "ap-northeast-1",
//     userPoolId: "ap-northeast-1_7MOjWCB6O",
//     userPoolWebClientId: "5ern5rvv11d5ui19qgv7ahudgb"
//   }
// });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
