export default function ReloadIcon(): JSX.Element {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <path
          d="M427.004,133.961c-0.883-1.238-1.633-2.539-2.547-3.762l-0.008,0.004c-0.031-0.043-0.054-0.09-0.09-0.133
		l-57.523,43.152c40.942,54.578,35.347,132.199-13.008,180.559c-11.238,11.234-24.348,20.152-38.5,26.859
		c-2.789,1.289-5.547,2.625-8.457,3.738l5.976-26.782l3.074-13.41l-0.086,0.028l0.039-0.18l-108.887,34.172L151.8,395.442
		l0.066,0.062l-0.176,0.054L278.386,512l10.817-48.469c43.543-6.965,84.086-27.519,115.473-58.906
		C477.054,332.242,486.211,216.746,427.004,133.961z"
        ></path>
        <path
          d="M158.265,158.218c11.234-11.234,24.344-20.152,38.496-26.859c2.789-1.289,5.551-2.629,8.461-3.742
		l-5.98,26.786l-3.074,13.406l0.09-0.027l-0.043,0.179l108.891-34.172l55.187-17.234l-0.07-0.062l0.176-0.054l-84.512-77.672
		l-42.023-38.73l-0.019,0.09L233.707,0l-10.821,48.469c-43.539,6.961-84.082,27.515-115.469,58.902
		c-73.5,73.5-81.965,191.535-19.687,274.554l57.523-43.148C104.312,284.199,109.906,206.578,158.265,158.218z"
        ></path>
      </g>
    </svg>
  );
}
